import React from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import { rem } from "polished"

const RowEl = styled.div`
  display: flex;
  width: 100%;
  overflow-x: initial;
  flex-wrap: wrap;

    ${props =>
      !props.noGutter &&
      css`
        // padding: ${rem(15)} 0;
      `}

  ${props =>
    props.noGutterTop == true &&
    css`
      padding-top: 0;
    `}  

  ${props =>
    props.noGutterBottom == true &&
    css`
      padding-bottom: 0;
    `} 

  @media ${props => props.theme.smallDown} {
    flex-wrap: wrap;
  }
`

const Row = props => {
  return (
    <RowEl
      noGutter={props.noGutter}
      className={props.className}
      noGutterTop={props.noGutterTop}
      noGutterBottom={props.noGutterBottom}
      style={props.style}
    >
      {props.children}
    </RowEl>
  )
}

export default Row

Row.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  noGutter: PropTypes.bool,
  noGutterTop: PropTypes.bool,
  noGutterBottom: PropTypes.bool,
  style: PropTypes.object,
}
