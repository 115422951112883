import React from "react"
import styled from "styled-components"
import { rem } from "polished"

const Container = styled.section`
  padding: ${rem(200)} 0;
  background-color: ${props => props.theme.colorSand};

  @media ${props => props.theme.largeDown} {
    padding: ${rem(120)} 0;
  }

  @media ${props => props.theme.smallDown} {
    padding: ${rem(60)} 0;
  }

  > div {
    ${props => props.theme.gridContainer()};
  }
`

const Intro = styled.header`
  > h2 {
    max-width: ${rem(580)};
    margin: 0 auto;
    text-align: center;
  }

  > .-text {
    ${props => props.theme.gridGrid()};

    margin-top: ${rem(80)};

    @media ${props => props.theme.smallDown} {
      margin-top: ${rem(30)};
    }

    > div {
      ${props => props.theme.gridCell(6)};

      margin-left: auto;

      @media ${props => props.theme.smallDown} {
        ${props => props.theme.gridCell(12)};
      }

      > .-btn {
        margin-top: ${rem(30)};

        @media ${props => props.theme.smallDown} {
          text-align: center;
        }
      }
    }
  }
`

const Enterprise = () => (
  <Container>
    <div>
      <Intro>
        <h2 className="styled-h3">
          Enterprise
          <br />
          Solutions
        </h2>

        <div className="-text">
          <div>
            <p className="styled-p-leading">
              Clickotine works with payers, employers, and providers to help
              improve member outcomes, health and savings. Use our customized
              calculator to estimate the financial impact Clickotine will have
              in your organization
            </p>
          </div>
        </div>
      </Intro>
    </div>
  </Container>
)

export default Enterprise
