import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import { rem } from "polished"

import Button from "../../button"

import { ReactComponent as LogoSvg } from "../../../assets/images/logos/logo-white.svg"
import { ReactComponent as TherapeuticsLogoSvg } from "../../../assets/images/logos/click-therapeutics-white.svg"

const Container = styled.section`
  width: 100%;
  position: relative;
  color: ${props => props.theme.colorWhite};
`

const Background = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;

  &::after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 0) 60%
    );
  }

  .gatsby-image-wrapper {
    height: 100%;
  }
`

const Inner = styled.div`
  ${props => props.theme.gridContainer()};
  ${props => props.theme.gridGrid()};

  > div {
    ${props => props.theme.gridCell(6)};

    min-height: 90vh;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media ${props => props.theme.smallDown} {
      ${props => props.theme.gridCell(8)};
    }
  }
`

const Head = styled.header`
  margin-top: 13vh;
  width: 100%;
  max-width: ${rem(530)};

  > .-logo {
    width: 100%;
    height: 0;
    padding-bottom: 16%;
    position: relative;

    svg {
      width: 100%;
      height: 100%;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  > .-powered {
    margin-top: ${rem(50)};
    margin-bottom: ${rem(10)};
    opacity: 0.7;
  }

  > .-logo-therapeutics {
    width: ${rem(150)};
    height: ${rem(45)};
  }
`

const Intro = styled.div`
  margin-top: 20vh;
  margin-bottom: 13vh;
  width: 100%;
  max-width: ${rem(530)};

  > p {
    max-width: ${rem(380)};
    margin: ${rem(30)} 0;
  }
`

const Hero = ({ data }) => {
  return (
    <Container>
      <Background>
        <Img fluid={data.image.childImageSharp.fluid} />
      </Background>

      <Inner>
        <div>
          <Head>
            <h1 className="is-vhidden">Clickotine®</h1>

            <div className="-logo">
              <LogoSvg aria-hidden="true" />
            </div>

            <div className="-powered">
              Powered by <span className="is-vhidden">Click Therapeutics</span>
            </div>

            <TherapeuticsLogoSvg
              className="-logo-therapeutics"
              aria-hidden="true"
            />
          </Head>

          <Intro>
            <h2 className="styled-h3">
              Finally, personalized help to quit smoking
            </h2>

            <p className="styled-p">
              Clickotine is a clinically validated fully digital smoking
              cessation program.
            </p>
            <Button to="https://try.clickotine.com">Start Today</Button>
          </Intro>
        </div>
      </Inner>
    </Container>
  )
}

Hero.propTypes = {
  data: PropTypes.object,
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        image: file(
          sourceInstanceName: { eq: "images" }
          relativePath: { eq: "photos/hero.jpg" }
        ) {
          childImageSharp {
            fluid(maxWidth: 4096) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    `}
    render={data => <Hero data={data} {...props} />}
  />
)
