import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import { rem } from "polished"

import Link from "../../link"
import Button from "../../button"
import { ReactComponent as AppStoreSvg } from "../../../assets/images/badges/app-store.svg"
import { ReactComponent as GooglePlaySvg } from "../../../assets/images/badges/google-play.svg"

const Container = styled.section`
  width: 100%;
  position: relative;
  padding: ${rem(120)} 0 ${rem(40)};
  color: ${props => props.theme.colorWhite};

  @media ${props => props.theme.smallDown} {
    padding-top: ${rem(60)};
  }
`

const Background = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;

  .gatsby-image-wrapper {
    height: 100%;
  }
`

const Info = styled.div`
  ${props => props.theme.gridContainer()}

  text-align: center;
`

const EmailUs = styled.div`
  margin: ${rem(80)} 0;

  a {
    width: 100%;
    max-width: ${rem(250)};
  }
`

const Badges = styled.div`
  margin-bottom: ${rem(80)};

  a {
    margin: ${rem(10)} ${rem(30)};
    display: inline-block;

    &:hover {
      opacity: 0.8;
    }

    &:active {
      opacity: 0.6;
    }
  }

  svg {
    width: ${rem(150)};
    height: ${rem(45)};
    fill: #000;
  }
`

const Foot = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  text-transform: uppercase;
  letter-spacing: 0.175em;
  margin: 0 ${rem(-8)};

  a {
    margin: 0 ${rem(8)};
    &:hover {
      opacity: 0.8;
    }

    &:active {
      opacity: 0.6;
    }
  }
`

const Demo = ({ data }) => (
  <Container>
    <Background>
      <Img fluid={data.image.childImageSharp.fluid} />
    </Background>

    <Info>
      <h2 className="styled-h3">Request a Demo</h2>

      <EmailUs>
        <Button to="mailto:info@clickotine.com">Email us</Button>
      </EmailUs>

      <Badges>
        <Link
          title="App Store"
          target="_blank"
          to="https://apps.apple.com/us/app/clickotine/id1065284802"
        >
          <AppStoreSvg aria-label="App Store" />
        </Link>

        <Link
          title="Google Play"
          target="_blank"
          to="https://play.google.com/store/apps/details?id=com.ct101&hl=en"
        >
          <GooglePlaySvg aria-label="Google Play" />
        </Link>
      </Badges>
      <h6 className="styled-h6" data-testid="copyright">
        © {new Date().getFullYear()} Click Therapeutics, Inc. All Rights
        Reserved.
      </h6>
      <Foot>
        <Link to="https://www.clicktherapeutics.com/terms.html" target="_blank">
          Terms of Use
        </Link>
        <Link
          to="https://www.clicktherapeutics.com/privacy.html"
          target="_blank"
        >
          Privacy Policy
        </Link>
        <Link to="https://clicktherapeutics.com" target="_blank">
          ClickTherapeutics.com
        </Link>
      </Foot>
    </Info>
  </Container>
)

Demo.propTypes = {
  data: PropTypes.object,
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        image: file(
          sourceInstanceName: { eq: "images" }
          relativePath: { eq: "photos/demo.jpg" }
        ) {
          childImageSharp {
            fluid(maxWidth: 4096) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    `}
    render={data => <Demo data={data} {...props} />}
  />
)
