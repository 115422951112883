import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { rem } from "polished"

import Link from "./link"

import { ReactComponent as ArrowRight } from "../assets/images/icons/arrow-right.svg"

const Container = styled.button`
  padding: 1em 2.5em;
  display: inline-block;
  overflow: visible;
  font-size: ${rem(15)};
  letter-spacing: 0.175em;
  text-align: center;
  text-transform: uppercase;
  border: 1px solid;
  border-radius: 4em;

  &:hover {
    svg {
      transform: translateX(0.3em);
    }
  }

  &:active {
    opacity: 0.6;
  }

  svg {
    width: 3.75em;
    height: 1.1em;
    margin: 0 auto 0.3em;
    display: block;
    transition: transform 0.3s ${props => props.theme.easingDefault};
  }
`

const Button = ({ children, to, ...rest }) => {
  return (
    <Container as={to ? Link : undefined} to={to} {...rest}>
      <ArrowRight aria-hidden="true" />
      {children}
    </Container>
  )
}

Button.propTypes = {
  children: PropTypes.node,
  to: PropTypes.string,
}

export default Button
