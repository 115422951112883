import React from "react"
import PropTypes from "prop-types"
// import breakpoints from "../theme/sections/mq"
import { rem } from "polished"

import styled, { css } from "styled-components"

const Container = styled.div`
  // border: 1px solid red;

  ${props =>
    props.top &&
    css`
      @media ${props => props.theme.smallUp} {
        padding-top: ${rem(100)};
      }

      @media ${props => props.theme.smallDown} {
        padding-top: ${rem(50)};
      }
    `}

  ${props =>
    props.bottom &&
    css`
      @media ${props => props.theme.smallUp} {
        padding-bottom: ${rem(100)};
      }

      @media ${props => props.theme.smallDown} {
        padding-bottom: ${rem(50)};
      }
    `}
`

class Spacer extends React.Component {
  constructor(props) {
    super(props)
  }

  // styles = {}

  // resizeListener = () => {
  //   if (this.props.spaceTop) {
  //     if (window.matchMedia(breakpoints.smallUp).matches) {
  //       this.styles.paddingTop = `${rem(150)}`
  //     } else {
  //       this.styles.paddingTop = `${rem(50)}`
  //     }
  //   }

  //   if (this.props.spaceBottom) {
  //     if (window.matchMedia(breakpoints.smallUp).matches) {
  //       this.styles.paddingBottom = `${rem(150)}`
  //     } else {
  //       this.styles.paddingBottom = `${rem(50)}`
  //     }
  //   }

  //   this.forceUpdate()
  // }

  // componentDidMount() {
  //   window.addEventListener(`resize`, this.resizeListener)
  //   this.resizeListener()
  // }

  // componentWillUnmount() {
  //   window.removeEventListener(`resize`, this.resizeListener)
  // }

  // render() {
  //   const clonedElement = React.cloneElement(this.props.children, {
  //     style: { ...this.styles },
  //   })

  //   return <React.Fragment>{clonedElement}</React.Fragment>
  // }

  render() {
    return (
      <Container top={this.props.top} bottom={this.props.bottom}>
        {this.props.children}
      </Container>
    )
  }
}

export default Spacer

Spacer.propTypes = {
  top: PropTypes.bool,
  bottom: PropTypes.bool,
  children: PropTypes.node,
}
