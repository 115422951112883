import React from "react"
import styled from "styled-components"
import { rem } from "polished"

import Button from "../../button"

const Container = styled.section`
  padding: ${rem(200)} 0;
  color: ${props => props.theme.colorWhite};
  background-color: ${props => props.theme.colorTangerine};

  @media ${props => props.theme.largeDown} {
    padding: ${rem(120)} 0;
  }

  @media ${props => props.theme.smallDown} {
    padding: ${rem(60)} 0;
  }

  > div {
    ${props => props.theme.gridContainer()};
  }
`

const Intro = styled.header`
  > h2 {
    max-width: ${rem(580)};
    margin: 0 auto;
    text-align: center;
  }

  > .-text {
    ${props => props.theme.gridGrid()};

    margin-top: ${rem(80)};

    @media ${props => props.theme.smallDown} {
      margin-top: ${rem(30)};
    }

    > div {
      ${props => props.theme.gridCell(6)};

      margin-left: auto;

      @media ${props => props.theme.smallDown} {
        ${props => props.theme.gridCell(12)};
      }

      > .-btn {
        margin-top: ${rem(30)};

        @media ${props => props.theme.smallDown} {
          text-align: center;
        }
      }
    }
  }
`

const Stats = styled.div`
  margin-top: ${rem(80)};
  margin-bottom: ${rem(80)};
  padding-top: ${rem(80)};
  padding-bottom: ${rem(80)};
  border-top: 1px solid;
  border-bottom: 1px solid;

  @media ${props => props.theme.smallDown} {
    margin-top: ${rem(40)};
    margin-bottom: ${rem(40)};
    padding-top: ${rem(40)};
    padding-bottom: ${rem(40)};
  }

  > ul {
    ${props => props.theme.gridGrid()};

    @media ${props => props.theme.smallDown} {
      ${props => props.theme.gridGridGutterY(40)};
    }

    > li {
      ${props => props.theme.gridCell(4)};

      @media ${props => props.theme.smallDown} {
        ${props => props.theme.gridCell(12)};
      }
    }
  }
`

const StatsItem = styled.div`
  padding: 0 ${rem(30)};
  text-align: center;

  .styled-h2 {
    display: block;
    margin-bottom: ${rem(30)};

    @media ${props => props.theme.xlargeDown} {
      margin-bottom: ${rem(15)};
      font-size: ${rem(55)};
    }
  }
`

const Footnotes = styled.footer`
  ${props => props.theme.gridGrid()};

  > ol {
    ${props => props.theme.gridCell(10)};

    counter-reset: counter;
    opacity: 0.7;

    > li {
      counter-increment: counter;

      &::before {
        content: counter(counter) ". ";
      }

      + li {
        margin-top: ${rem(20)};
      }
    }
  }
`

const Outcomes = () => (
  <Container>
    <div>
      <Intro>
        <h2 className="styled-h3">Groundbreaking Clinical Outcomes</h2>

        <div className="-text">
          <div>
            <p className="styled-p-leading">
              Results of a landmark 416-participant study demonstrated
              exceptional engagement and efficacy
            </p>

            <div className="-btn">
              <Button to="https://mhealth.jmir.org/2017/4/e56/" target="_blank">
                See research
              </Button>
            </div>
          </div>
        </div>
      </Intro>

      <Stats>
        <ul>
          <li>
            <StatsItem>
              <b className="styled-h2">45.2%</b>

              <span>
                of the participants (n=188) had stopped smoking at the end of
                the study (no cigarettes, not even a puff, for at least 7 days)
              </span>
            </StatsItem>
          </li>

          <li>
            <StatsItem>
              <b className="styled-h2">35.3%</b>

              <span>
                of participants (n=147) had quit smoking at the 6-month mark (no
                cigarettes, not even a puff, for at least 30 days)
              </span>
            </StatsItem>
          </li>

          <li>
            <StatsItem>
              <b className="styled-h2">100.6</b>

              <span>
                On average, participants opened the Clickotine app 100.6 times
                and performed an additional 214.4 interactions over the 8-week
                study period
              </span>
            </StatsItem>
          </li>
        </ul>
      </Stats>

      <Footnotes>
        <ol>
          <li className="styled-p">
            Iacoviello BM, Steinerman JR, Klein DB, Silver TL, Berger AG, Luo
            SX, Schork NJ. Clickotine, A Personalized Smartphone App for Smoking
            Cessation: Initial Evaluation. JMIR Mhealth Uhealth 2017;5(4):e56
          </li>

          <li className="styled-p">
            Iacoviello BM, Steinerman JR, Klein DB, Gao V, Kruse GR, & Schork
            NJ. Six-month outcomes from Clickotine: a digital therapeutic
            program for smoking cessation. Poster presented at the Society for
            Research on Nicotine and Tobacco 23rd Annual Meeting, March, 2017;
            Florence, Italy.
          </li>
        </ol>
      </Footnotes>
    </div>
  </Container>
)

export default Outcomes
