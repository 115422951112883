import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import { rem } from "polished"

const Container = styled.section`
  padding-top: ${rem(120)};
  background-color: ${props => props.theme.colorBlack};
  color: ${props => props.theme.colorPaleGrey};

  @media ${props => props.theme.smallDown} {
    padding-top: ${rem(60)};
  }
`

const Inner = styled.div`
  ${props => props.theme.gridContainer()}
`

const Info = styled.figure`
  ${props => props.theme.gridGrid()}

  justify-content: center;
  text-align: center;

  > div {
    ${props => props.theme.gridCell(9)}
  }

  h2 {
    margin-bottom: ${rem(30)};
  }
`

const Image = styled.figure`
  margin-top: ${rem(80)};

  @media ${props => props.theme.smallDown} {
    margin-top: ${rem(30)};
  }
`

const FeatureCoaching = ({ data }) => (
  <Container>
    <Inner>
      <Info>
        <div>
          <h2 className="styled-h3">
            Click
            <br />
            Digital Care
          </h2>

          <p className="styled-p-leading">
            Click Digital Care is an AI-augmented patient success program that
            wraps-around each Click digital therapeutic to help patients succeed
            with the therapy
          </p>
        </div>
      </Info>

      <Image>
        <Img fluid={data.image.childImageSharp.fluid} />
      </Image>
    </Inner>
  </Container>
)

FeatureCoaching.propTypes = {
  data: PropTypes.object,
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        image: file(
          sourceInstanceName: { eq: "images" }
          relativePath: { eq: "photos/feature-coaching.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 1336) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    `}
    render={data => <FeatureCoaching data={data} {...props} />}
  />
)
