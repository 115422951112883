import React from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import { rem } from "polished"

const ColumnEl = styled.div`

  width: 100%;
  flex-shrink: 0;
  // box-shadow: inset 0 0 0 1px #000;

  ${props =>
    !props.noGutter &&
    css`
      padding: 0 ${rem(10)};
    `}


  ${props =>
    props.noGutterTop == true &&
    css`
      padding-top: 0;
    `}  

  ${props =>
    props.noGutterBottom == true &&
    css`
      padding-bottom: 0;
    `}  

  ${props =>
    props.smallUp &&
    css`
      @media ${props => props.theme.smallUp} {
        width: ${parseFloat((props.smallUp * 8.333).toFixed(2))}%;
      }
    `}

  ${props =>
    props.smallDown &&
    css`
      @media ${props => props.theme.smallDown} {
        width: ${parseFloat((props.smallDown * 8.333).toFixed(2))}%;
      }
    `}

    ${props =>
      props.smallUpOffset &&
      css`
        @media ${props => props.theme.smallUp} {
          margin-left: ${parseFloat((props.smallUpOffset * 8.333).toFixed(2))}%;
        }
      `}

    ${props =>
      props.smallDownOffset &&
      css`
        @media ${props => props.theme.smallDown} {
          margin-left: ${parseFloat(
            (props.smallDownOffset * 8.333).toFixed(2)
          )}%;
        }
      `}      
`

const Column = props => {
  return (
    <ColumnEl
      smallUpOffset={props.smallUpOffset}
      smallDownOffset={props.smallDownOffset}
      smallUp={props.smallUp}
      smallDown={props.smallDown}
      className={props.className}
      noGutter={props.noGutter}
      noGutterTop={props.noGutterTop}
      noGutterBottom={props.noGutterBottom}
      style={props.style}
    >
      {props.children}
    </ColumnEl>
  )
}

export default Column

Column.propTypes = {
  smallUpOffset: PropTypes.any,
  smallDownOffset: PropTypes.any,
  smallUp: PropTypes.any,
  smallDown: PropTypes.any,
  className: PropTypes.string,
  children: PropTypes.node,
  noGutter: PropTypes.bool,
  noGutterTop: PropTypes.bool,
  noGutterBottom: PropTypes.bool,
  style: PropTypes.object,
}
