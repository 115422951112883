import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import { rem } from "polished"

const Container = styled.section`
  padding: ${rem(200)} 0;
  color: ${props => props.theme.colorPaleGrey};

  @media ${props => props.theme.largeDown} {
    padding: ${rem(120)} 0;
  }

  @media ${props => props.theme.smallDown} {
    padding: ${rem(60)} 0;
  }
`

const Inner = styled.div`
  ${props => props.theme.gridContainer()}
  ${props => props.theme.gridGrid()}
  ${props => props.theme.gridGridGutterX()}

  align-items: center;
  justify-content: space-around;

  @media ${props => props.theme.smallDown} {
    ${props => props.theme.gridGridGutterY(30)}
  }
`

const Image = styled.figure`
  ${props => props.theme.gridCell(5.5)}

  @media ${props => props.theme.smallDown} {
    ${props => props.theme.gridCell(10)}
  }
`

const Info = styled.figure`
  ${props => props.theme.gridCell(6)}

  text-align: center;

  @media ${props => props.theme.smallDown} {
    ${props => props.theme.gridCell(10)}
  }

  > h2 {
    margin-bottom: ${rem(30)};
  }
`

const FeatureSocial = ({ data }) => (
  <Container>
    <Inner>
      <Image>
        <Img fluid={data.image.childImageSharp.fluid} />
      </Image>

      <Info>
        <h2 className="styled-h3">
          Social
          <br />
          Engagement
        </h2>

        <p className="styled-p-leading">
          Real-life support from friends, family and community. Quitting with a
          small quit team is proven to be a powerful tool for engagement
        </p>
      </Info>
    </Inner>
  </Container>
)

FeatureSocial.propTypes = {
  data: PropTypes.object,
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        image: file(
          sourceInstanceName: { eq: "images" }
          relativePath: { eq: "photos/feature-social.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 1336) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    `}
    render={data => <FeatureSocial data={data} {...props} />}
  />
)
