import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import { rem } from "polished"

const Container = styled.section`
  padding: ${rem(200)} 0;
  color: ${props => props.theme.colorPaleGrey};

  @media ${props => props.theme.largeDown} {
    padding: ${rem(120)} 0;
  }

  @media ${props => props.theme.smallDown} {
    padding: ${rem(60)} 0;
  }

  > div {
    ${props => props.theme.gridContainer()};
  }
`

const Info = styled.figure`
  ${props => props.theme.gridGrid()}

  justify-content: center;
  text-align: center;
  > h2 {
    margin: 0 auto;
    text-align: center;
    margin-bottom: ${rem(30)};
  }
`
const Logos = styled.div`
  ${props => props.theme.gridContainer()}
  ${props => props.theme.gridGrid()}
  ${props => props.theme.gridGridGutterX(30)}
  justify-content: space-around;
  align-items: center;
  > figure {
      ${props => props.theme.gridCell(6)}
      @media ${props => props.theme.smallDown} {
        ${props => props.theme.gridCell(12)}
      }
    }
  }
`

const Image = styled.figure`
  margin-top: ${rem(80)};
  max-width: ${rem(400)};

  @media ${props => props.theme.smallDown} {
    margin-top: ${rem(30)};
  }
`

const FeatureCoaching = ({ data }) => (
  <Container>
    <div>
      <Info>
        <div>
          <h2 className="styled-h3">Our Partners</h2>
        </div>
      </Info>

      <Logos>
        <Image>
          <Img fluid={data.solera.childImageSharp.fluid} />
        </Image>
        <Image>
          <Img fluid={data.magellan.childImageSharp.fluid} />
        </Image>
      </Logos>
    </div>
  </Container>
)

FeatureCoaching.propTypes = {
  data: PropTypes.object,
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        magellan: file(
          sourceInstanceName: { eq: "images" }
          relativePath: { eq: "partners/MagellanLogo.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 400) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        solera: file(
          sourceInstanceName: { eq: "images" }
          relativePath: { eq: "partners/SoleraLogo.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 400) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    `}
    render={data => <FeatureCoaching data={data} {...props} />}
  />
)
