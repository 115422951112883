import React from "react"

import Meta from "../components/meta"
import Hero from "../components/pages/index/hero"
import Outcomes from "../components/pages/index/outcomes"
import FeaturePlan from "../components/pages/index/feature-plan"
import FeatureFight from "../components/pages/index/feature-fight"
import FeatureSocial from "../components/pages/index/feature-social"
import FeatureMessaging from "../components/pages/index/feature-messaging"
import FeatureMedication from "../components/pages/index/feature-medication"
import FeatureFinance from "../components/pages/index/feature-finance"
import FeatureCoaching from "../components/pages/index/feature-coaching"
import FeatureDashboard from "../components/pages/index/feature-dashboard"
import FeaturePartners from "../components/pages/index/feature-partners"
import Enterprise from "../components/pages/index/enterprise"
import Calculator from "../components/pages/index/calculator"
import Demo from "../components/pages/index/demo"

const meta = {
  title: `Finally, personalized help to quit smoking.`,
  description: `A Comprehensive Digital Smoking Cessation Program. Improve Outcomes, Health & Savings. Enterprise Solutions - Payers, Employers, & Providers. Request a Demo.`,
  keywords: `Click Therapeutics, ClickTx, Digital Therapeutics, Digital Medicine, DTx, SaMD, mHealth, Tobacco, Vaping, Nicotine, Clinical Trial, healthcare, smoking penalty, benefits enrollment, Human Resources, Controlled Breathing, Social Engagement, Personalized Messaging, Medication Access, Digital Diversions, Financial Incentives, NRT, Nicotine Replacement Therapy, Second-hand Smoke`,
}

const IndexPage = () => (
  <React.Fragment>
    <Meta {...meta} />

    <Hero />

    <Outcomes />

    <FeaturePlan />

    <FeatureFight />

    <FeatureSocial />

    <FeatureMessaging />

    <FeatureMedication />

    <FeatureFinance />

    <FeatureCoaching />

    <FeatureDashboard />

    <Enterprise />

    <FeaturePartners />

    <Calculator />

    <Demo />
  </React.Fragment>
)

export default IndexPage
