import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import { rem } from "polished"

const Container = styled.section`
  background-color: ${props => props.theme.colorSand};
`

const Inner = styled.div`
  ${props => props.theme.gridContainer()}
  ${props => props.theme.gridGrid()}
  ${props => props.theme.gridGridGutterX()}

  align-items: flex-end;
  justify-content: space-around;
`

const Image = styled.figure`
  ${props => props.theme.gridCell(6)}

  order: 1;

  @media ${props => props.theme.smallDown} {
    ${props => props.theme.gridCell(10)}
  }
`

const Info = styled.figure`
  ${props => props.theme.gridCell(6)}

  padding-top: ${rem(200)};
  padding-bottom: ${rem(200)};
  text-align: center;

  @media ${props => props.theme.largeDown} {
    padding-top: ${rem(120)};
    padding-bottom: ${rem(120)};
  }

  @media ${props => props.theme.smallDown} {
    ${props => props.theme.gridCell(10)}

    padding-top: ${rem(60)};
    padding-bottom: ${rem(30)};
  }

  > h2 {
    margin-bottom: ${rem(30)};
  }
`

const FeatureMessaging = ({ data }) => (
  <Container>
    <Inner>
      <Image>
        <Img fluid={data.image.childImageSharp.fluid} />
      </Image>

      <Info>
        <h2 className="styled-h3">
          Personalized
          <br />
          Messaging
        </h2>

        <p className="styled-p-leading">
          Adaptive text messages tailored to each individual's profile, and
          provide contextualized, personalized support and guidance
        </p>
      </Info>
    </Inner>
  </Container>
)

FeatureMessaging.propTypes = {
  data: PropTypes.object,
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        image: file(
          sourceInstanceName: { eq: "images" }
          relativePath: { eq: "photos/feature-messaging.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 1336) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    `}
    render={data => <FeatureMessaging data={data} {...props} />}
  />
)
